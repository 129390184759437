<script>
import ARScheme from '@/classes/modules/AR/ARScheme'
import ARScene from '@/classes/modules/AR/ARScene'
import { mapState } from 'vuex'

export default {

  props: {
    title: {
      type: String,
      default: '标题'
    },
    scheme: {
      type: ARScheme,
      default: undefined
    },
    scene: {
      type: ARScene,
      default: undefined
    },
    publish: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      name: '',
      pic: '',
      loading: false,
    }
  },
  computed: {
    ...mapState({
      editSceneData: state => state.ar.editSceneData,
    }),
    showPic () {
      if (this.pic) {
        return `${ this.$file_url }${ this.pic }`
      }
      return ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      console.log('init')
      console.log(this.scheme)
      if (this.scheme) {
        this.pic = this.scheme.pic
        this.name = this.scheme.name
      }
    },
    closeHanler () {
      console.log('eH')
      this.$modal.hide('editModal')
      this.$emit('closeHandler')
    },
    async sureHandler () {
      if (this.loading) {
        return
      }
      if (this.pic === '') {
        this.$notice({
          desc: '请上传封面图',
          type: 'error'
        })
        return
      }
      if (this.name === '') {
        this.$notice({
          desc: '请输入方案名',
          type: 'error'
        })
        return
      }
      let next = false
      if (this.scheme.pic !== this.pic) {
        next = true
      }
      if (this.scheme.name !== this.name) {
        next = true
      }
      console.log(next)
      if (next) {
        this.loading = true
        if (this.scheme.id) {
          const res = await this.scheme.editCover({
            pic: this.pic,
            name: this.name
          })
          this.loading = false
          if (res.message === 'success') {
            this.$notice({
              desc: '修改成功',
              type: 'success'
            })
            if (this.editSceneData) {
              this.editSceneData.save = true
            }
            this.closeHanler()
          }
        } else {
          let toastDesc = ''
          if (!this.scheme.id) {
            if (this.scheme.status) {
              toastDesc = '发布成功'
            } else {
              toastDesc = '保存成功'
            }
          }
          const res = await this.scheme.create({
            pic: this.pic,
            name: this.name,
          })
          this.loading = false
          if (res.message === 'success') {
            if (!this.publish) {
              // 后面需要发布时不弹toast
              this.$notice({
                desc: toastDesc,
                type: 'success'
              })
            }
            this.scheme.id = res.contents.plan.id
            if (this.editSceneData) {
              this.editSceneData.save = true
            }
            if (this.publish) {
              this.$emit('publishHandler')
            }
            this.closeHanler()
          }
        }

      }

    },
    touchImg () {
      this.$refs.img.click()
    },
    async changeImg(){
      let file = this.$refs.img.files[0];
      let preivewData = await this.previewImg(file)
      let data = {
        preview: preivewData.url,
      }
      // this.$set(this, `${type}img`, data);
      // 上传
      let uploadRes = await this.uploadFile(file)
      console.log(uploadRes)
      this.pic = uploadRes
      // this.$set(this[`${type}img`], 'url', uploadRes);
      // this.$api.package.doModified();
    },
    /** 获取预览图片地址 */
    previewImg(item){
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (event) => {
          /** 地址 */
          let url = event.target.result;
          resolve({ url })
        }
      })
    },
    async uploadFile (item) {
      const res = await this.$api.oss.uploadFile({
        file: item
      })

      return res


      // let formdata = new FormData();
      // formdata.append('Filedata',item);
      // let loginUserCode = this.$tools.getCookie('loginUserCode');
      // let config = {
      //   headers: {
      //     loginUserCode: JSON.stringify(loginUserCode),
      //     // loginUserCode: loginUserCode,

      //   }
      // }
      // let res = await this.$http.custom_request(`https://${this.$domain_name}/project/upLoadImg`, formdata, config);
      // return res;
    },
  }
}
</script>

<template>
<div
  class="v_ar_common_edit_scheme d-felx flex-column"
>
  <div
    v-if="0"
    class="title_group"
  >
    <div
      class="title"
    >
      {{ title }}
    </div>
    <div
      class="close"
    ></div>
  </div>
  <div
    class="edit_group"
  >
    <div
      class="img_group d-flex align-start"
    >
      <div
        class="key d-flex align-center"
      >
        <div
          class="symbol"
        >
          *
        </div>
        <div
          class="text"
        >
          封面图
        </div>
      </div>
      <div
        class="upload_group bbox"
        @click.stop="touchImg"
      >
        <div
          v-if="!showPic"
          class="placeholder d-flex flex-column align-center justify-center"
        >
          <img src="~@/assets/images/ar/edit/img_placeholder.png" alt="">
          <div
            class="hint"
          >
            点击添加封面图
          </div>
          <div
            class="hint"
          >
            建议单张大小不超过 10 MB
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          ref="img"
          style="display:none;"
          @change="changeImg('web')"
        >
        <div
          v-if="showPic"
          class="img_show"
          :style="{
            backgroundImage: `url(${ showPic })`
          }"
        ></div>
      </div>
    </div>
    <div
      class="name_group d-flex align-center"
    >
      <div
        class="key d-flex align-center"
      >
        <div
          class="symbol"
        >
          *
        </div>
        <div
          class="text"
        >
          方案名
        </div>
      </div>
      <div
        class="input_name_group bbox"
      >
        <input
          v-model="name"
          type="text"
          class="text bbox"
          maxlength="15"
          placeholder="输入方案名（最多15个字符）"
        >
      </div>

    </div>
  </div>
  <div
    class="operation_group flex-shrink-0 d-flex align-center bbox justify-end"
  >
    <div
      class="btn btn_light cancel"
      @click.stop="closeHanler"
    >
      取消
    </div>
    <div
      class="btn btn_dark sure"
      @click.stop="sureHandler"
    >
      确定
    </div>
  </div>

</div>
</template>

<style scoped lang="scss">
.v_ar_common_edit_scheme {
  width: 100%;
  height: 100%;
  .title_group {
    width: 100%;
    padding: 10px 40px;
    .title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
    }
  }
  .edit_group {
    width: 100%;
    padding: {
      top: 20px;
      left: 40px;
      right: 40px;
    }
    .key {
      padding-right: 16px;
      .symbol {
        color: #CF0000;
      }
      .text {
        font-size: 16px;
      }
    }
    .upload_group {
      @include transition;
      width: 400px;
      height: 225px;
      border: 1px solid #E5E7EA;
      border-radius: 4px;
      &:hover {
        border-color: $main-blue;
      }
      .placeholder {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        .hint {
          color: #bbb;
          font-size: 14px;
          padding-top: 8px;
        }
      }
      .img_show {
        @include background;
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-position: center;
      }
    }
    .img_group {
      padding-bottom: 20px;
    }

    .input_name_group {
      width: 400px;
      line-height: 34px;
      height: 36px;
      border: 1px solid #E5E7EA;
      border-radius: 4px;
      &:hover {
        border-color: $main-blue;
      }
      .text {
        @include placeholder(#bbb);
        width: 100%;
        font-size: 14px;
        line-height: 34px;
        outline: none;
        padding: 0 10px;
        border: none;
        color: #333;
        border-radius: 4px;
        background-color: transparent;
      }
    }
    .name_group {
      padding-bottom: 50px;
    }
  }
  .operation_group {
    width: 100%;
    font-size: 14px;
    border-top: 1px solid #f8f8f8;
    padding: 10px 30px;
    .btn{
      cursor: pointer;
      line-height: 36px;
      padding: 0 30px;
      text-align: center;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
}
</style>
