import ARScheme from '@/classes/modules/AR/ARScheme'
import { listForClasses } from '@/classes'
import request from '@/api/request'
/**
 * 元基建场景
 */
class ARScene {
  accountList
  address
  createTime
  createUserid
  filename
  filesize
  id
  lat
  lot
  name
  pic
  planList
  updateTime
  url
  fdetail
  resUrl
  resUuid
  fid



  constructor (params) {
    const {
      accountList,
      address,
      createTime,
      createUserid,
      filename,
      filesize,
      id,
      lat,
      lot,
      name,
      pic,
      planList,
      updateTime,
      url,
      fdetail,
      resUrl,
      resUuid,
      fid,
    } = params ?? {}

    this.accountList = accountList
    this.address = address
    this.createTime = createTime
    this.createUserid = createUserid
    this.filename = filename
    this.filesize = filesize
    this.id = id
    this.lat = lat
    this.lot = lot
    this.name = name
    this.pic = pic
    this.planList = planList
    this.updateTime = updateTime
    this.url = url
    this.accountList = accountList
    this.createUserid = createUserid
    this.fdetail = fdetail
    this.resUrl = resUrl
    this.resUuid = resUuid
    this.fid = fid
    if (planList) {
      this.planList = listForClasses({
        list: planList,
        classes: ARScheme
      })
    }
  }


}

export default ARScene