import request from '@/api/request'

class ARScheme {

  createTime
  id
  modelId
  name
  pic
  plancode
  status
  updateTime
  userId

  constructor (params) {
    const {
      createTime,
      id,
      modelId,
      name,
      pic,
      plancode,
      status,
      updateTime,
      userId,
    } = params ?? {}

    this.createTime = createTime
    this.id = id
    this.modelId = modelId
    this.name = name
    this.pic = pic
    this.plancode = plancode
    this.status = status
    this.updateTime = updateTime
    this.userId = userId
  }

  create = async (params) => {
    const {
      pic,
      name,
    } = params
    const data = {
      id: this.id,
      status: this.status,
      plancode: this.plancode,
      modelId: this.modelId
    }
    if ((pic ?? '') !== '') {
      data.pic = pic
    }
    if ((name ?? '') !== '') {
      data.name = name
    }
    console.log(data)
    const res = await request.request(
      '/vr/createPlan',
      data
    )
    if (res.message === 'success') {
      this.name = name
      this.pic = pic
    }
    return res
  }

  save = async (params) => {
    const {
      plancode
    } = params
    const data = {
      id: this.id,
      status: this.status,
      plancode,
      pic: this.pic,
      name: this.name,
    }
    const res = await request.request(
      '/vr/updatePlan',
      data
    )
    if (res.message === 'success') {
      this.plancode = plancode
    }
    return res
  }

  editCover = async (params) => {
    const {
      pic,
      name,
    } = params
    const data = {
      id: this.id,
      status: this.status,
      plancode: this.plancode
    }
    if ((pic ?? '') !== '') {
      data.pic = pic
    }
    if ((name ?? '') !== '') {
      data.name = name
    }
    console.log(data)
    const res = await request.request(
      '/vr/updatePlan',
      data
    )
    if (res.message === 'success') {
      this.name = name
      this.pic = pic
    }
    return res
  }

  delete = async () => {
    const res = await request.request(
      '/vr/deletePlan',
      {
        id: this.id
      }
    )
    return res
  }

  editPublish = async (params) => {
    const {
      status
    } = params ?? {}
    const data = {
      id: this.id,
      status,
      pic: this.pic,
      name: this.name,
      plancode: this.plancode,
    }

    console.log(data)
    const res = await request.request(
      '/vr/updatePlan',
      data
    )
    if (res.message === 'success') {
      this.status = status
    }
    return res
  }
}

export default ARScheme