<script>
import ARScheme from '@/classes/modules/AR/ARScheme'
import ARScene from '@/classes/modules/AR/ARScene'
import { mapState } from 'vuex'

export default {
  props: {
    scheme: {
      type: ARScheme,
      default: undefined
    },
    scene: {
      type: ARScene,
      default: undefined
    },
    status: {
      type: Number,
      default: 1,
    }
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    statusText () {
      if (this.scheme) {
        return `确认${ !this.status ? '下线' : '发布' }吗?`
      }
      return ''
    },
    hintText () {
      if (this.scheme) {
        if (!this.status) {
          return '下线后，此方案将保存到草稿箱中'
        }
        return '发布后，所有修改的内容将同步到线上'
      }
      return ''
    },
    ...mapState({
      editSceneData: state => state.ar.editSceneData,
    }),
  },
  methods: {
    closeHandler () {
      // this.$modal.hide('')
      this.$emit('closeHandler')
    },
    async sureHandler () {
      console.log(this.scheme)
      if (this.loading) {
        return
      }
      this.loading = true
      // let status = this.scheme.status ? 0 : 1
      let status = this.status
      const res = await this.scheme.editPublish({
        status
      })
      if (res.message === 'success') {
        this.scheme.status = status
        if (this.scene.planList && this.scene.planList.length) {
          const index = this.scene.planList.findIndex((unit) => {
            return Number(unit.id) === Number(this.scheme.id)
          })
          if (index !== -1) {
            this.scene.planList.splice(index, 1)
          }
        }
        this.closeHandler()
        if (this.editSceneData && status) {
          this.editSceneData.save = true
        }
        this.$notice({
          desc: `${ status ? '发布' : '下线' }成功`,
          type: 'success'
        })

      }
    }
  }
}
</script>

<template>
<div
  v-loading="loading"
  class="v_ar_scheme_dialog_publish"
>
  <div
    class="hint_group"
  >
    <div
      class="hint"
    >
      {{ statusText }}
    </div>
    <div
      class="hint"
    >
      {{ hintText }}
    </div>
  </div>
  <div
    class="operation_group flex-shrink-0 d-flex align-center bbox justify-center"
  >
    <div
      class="btn btn_light cancel"
      @click.stop="closeHandler"
    >
      取消
    </div>
    <div
      class="btn btn_dark sure"
      @click.stop="sureHandler"
    >
      确定
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.v_ar_scheme_dialog_publish {
  .operation_group {
    width: 100%;
    font-size: 14px;
    border-top: 1px solid #f8f8f8;
    padding: 10px 30px;
    .btn{
      cursor: pointer;
      line-height: 36px;
      padding: 0 30px;
      text-align: center;
      border-radius: 4px;
      margin: 0 10px;
    }
  }
  .hint_group {
    padding: 20px;
    .hint {
      text-align: center;
      font-size: 14px;
      color: #333333;
      line-height: 19px;
    }
  }
}
</style>