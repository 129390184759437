var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_ar_common_edit_scheme d-felx flex-column" },
    [
      0
        ? _c("div", { staticClass: "title_group" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
            _c("div", { staticClass: "close" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "edit_group" }, [
        _c("div", { staticClass: "img_group d-flex align-start" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "upload_group bbox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.touchImg.apply(null, arguments)
                },
              },
            },
            [
              !_vm.showPic
                ? _c(
                    "div",
                    {
                      staticClass:
                        "placeholder d-flex flex-column align-center justify-center",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/ar/edit/img_placeholder.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hint" }, [
                        _vm._v("\n            点击添加封面图\n          "),
                      ]),
                      _c("div", { staticClass: "hint" }, [
                        _vm._v(
                          "\n            建议单张大小不超过 10 MB\n          "
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("input", {
                ref: "img",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: "image/*" },
                on: {
                  change: function ($event) {
                    return _vm.changeImg("web")
                  },
                },
              }),
              _vm.showPic
                ? _c("div", {
                    staticClass: "img_show",
                    style: {
                      backgroundImage: `url(${_vm.showPic})`,
                    },
                  })
                : _vm._e(),
            ]
          ),
        ]),
        _c("div", { staticClass: "name_group d-flex align-center" }, [
          _vm._m(1),
          _c("div", { staticClass: "input_name_group bbox" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name",
                },
              ],
              staticClass: "text bbox",
              attrs: {
                type: "text",
                maxlength: "15",
                placeholder: "输入方案名（最多15个字符）",
              },
              domProps: { value: _vm.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.name = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass:
            "operation_group flex-shrink-0 d-flex align-center bbox justify-end",
        },
        [
          _c(
            "div",
            {
              staticClass: "btn btn_light cancel",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.closeHanler.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      取消\n    ")]
          ),
          _c(
            "div",
            {
              staticClass: "btn btn_dark sure",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.sureHandler.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      确定\n    ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key d-flex align-center" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n          *\n        ")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n          封面图\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "key d-flex align-center" }, [
      _c("div", { staticClass: "symbol" }, [_vm._v("\n          *\n        ")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("\n          方案名\n        "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }